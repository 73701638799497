import React, {useContext, useMemo, useRef} from 'react';
import {RadioGroupContext} from '../../molecules/RadioGroup';
import Button from '../Button';
import {RadioSelectProps} from './types';

const RadioSelect = ({children, size = 'medium', value}: RadioSelectProps) => {
  const {name, selectedValue, onChange} = useContext(RadioGroupContext);
  const isChecked = useMemo(() => selectedValue === value, [selectedValue, value]);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleClick = () => {
    if (inputRef.current) {
      inputRef.current.checked = true;
      onChange?.(value);
    }
  };

  return (
    <div className="erg-select-button">
      <input
        ref={inputRef}
        id={`radio-${value}`}
        name={name}
        value={String(value)}
        type="radio"
        checked={isChecked}
        onChange={() => onChange?.(value)}
        className="sr-only"
      />
      <Button size={size} onClick={handleClick} isFullWidth flavor={isChecked ? 'primary' : 'regular'}>
        {children}
      </Button>
    </div>
  );
};

export default RadioSelect;
