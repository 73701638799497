import React from 'react';
import Card from '../../atoms/Card';
import moment from 'moment';
import classNames from 'classnames';
import {MessageItemProps} from './types';

const MessageItem: React.FC<MessageItemProps> = ({text, created_at, type}) => {
  const containerClasses = classNames('flex flex-col gap-1 erg-message-item', {
    'self-end items-end': type === 'inbound',
    'self-start items-start': type !== 'inbound',
  });
  const cardFlavor = type === 'inbound' ? 'success' : 'info';
  const formattedDateTime = moment(created_at).format('MMM D, YYYY, hh:mm A');

  return (
    <div className={containerClasses}>
      <Card rounded="xsmall" className="px-4 py-2 max-w-md text-gray-900 text-sm leading-normal" flavor={cardFlavor}>
        {text}
      </Card>

      <div className="flex items-baseline gap-1">
        <span className="text-gray-500 text-xs leading-normal">{formattedDateTime}</span>
      </div>
    </div>
  );
};

export default MessageItem;
