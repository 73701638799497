import React from 'react';
import classNames from 'classnames';
import {CountBadgeProps} from './types';

const CountBadge: React.FC<CountBadgeProps> = ({count, flavor = 'action'}) => {
  const flavorClasses = {
    primary: 'bg-blue-500',
    regular: 'bg-gray-500',
    attention: 'bg-orange-400',
    cta: 'bg-green-500',
    action: 'bg-red-600',
  };

  return (
    <div
      className={classNames(
        'erg-bubble-notification w-min h-min flex items-center min-w-5 min-h-5 font-base leading-none text-white justify-center rounded-full px-1.5 text-xs',
        flavorClasses[flavor],
        {hidden: count == null || count <= 0},
      )}
    >
      {count != null && count > 0 && <>{count}</>}
    </div>
  );
};

export default CountBadge;
